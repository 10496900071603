import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import icloud_1 from '../../../../../../../common/src/assets/image/icloud_1.png';
import iCloud_2 from '../../../../../../../common/src/assets/image/iCloud_2.png';
import iCloud_3 from '../../../../../../../common/src/assets/image/iCloud_3.png';
import iCloud_4 from '../../../../../../../common/src/assets/image/iCloud_4.png';
import iCloud_5 from '../../../../../../../common/src/assets/image/iCloud_5.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        Do you want to print your iCloud calendar? With PrintableCal, you can create printable calendars in Excel, Word, and PowerPoint, with your choice of over <Link to="/Documentation/Create-Calendar/Choose-a-Template">90 different calendar templates
        and layouts</Link>.
      </p>
      <p>
        Follow these steps to connect PrintableCal to your iCloud calendar:
      </p>
      <ol className="numbered">
        <li>
          Click the <strong>Add Calendar </strong>button on the{' '}
          <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources">
            Calendar Data Sources
          </Link>{' '}
          step and select the <strong>Online -&gt; </strong>
          <strong>iCloud</strong> option. The{' '}
          <strong>Add Calendar Source - iCloud</strong> window will appear.
          <br />
          <Image alt="" src={icloud_1} style={{ width: 690 }} />
        </li>
        <li>
          Open{' '}
          <a
            href="https://www.icloud.com/#calendar"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.icloud.com/#calendar
          </a>
          .
        </li>
        <li>
          Click the <strong>Calendar Sharing Info</strong> button located to the
          right of the name of the calendar you'd like to add.
          <br />
          <Image alt="" src={iCloud_2} style={{ width: 346 }} />
        </li>
        <li>
          Ensure the <strong>Public Calendar</strong> box is checked. This will
          enable PrintableCal to access the calendar URL.
          <br />
          <Image alt="" src={iCloud_3} style={{ width: 630 }} />
        </li>
        <li>
          Click the Copy Link button located below the URL.
          <br />
          <Image alt="" src={iCloud_4} style={{ width: 628 }} />
        </li>
        <li>
          Click the <strong>OK</strong> button to close the Calendar Sharing
          settings.
        </li>
        <li>
          After copying the link, the address field at the top of the{' '}
          <strong>Add Calendar Source - iCloud</strong> window should be
          automatically filled. If that doesn't happen, right-click the address
          field and select <strong>Paste</strong> from the popup menu, or press{' '}
          <strong>Ctrl+V</strong>.
        </li>
        <li>
          Enter the name of your iCloud calendar in the field below the calendar
          address field.
          <br />
          <br />
          <Image alt="" src={iCloud_5} style={{ width: 690 }} />
        </li>
        <li>
          The <strong>OK</strong> button will become enabled. Click the{' '}
          <strong>OK </strong>button to add your iCloud calendar to
          PrintableCal's list of available calendar sources.
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/iCloud"
      commentsId="commentsplus_post_141_489"
      title="Print iCloud Calendar"
      description="PrintableCal can create printable calendars containing events imported from iCloud calendars."
      keywords="print iCloud events, print iCloud calendar, printable iCloud, printable events, printable calendar, calendar template, import iCloud events, import iCloud calendar, Excel calendar"
      content={content}
    />
  );
};

export default Documentation;
